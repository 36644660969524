var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.general.name')},model:{value:(_vm.localItem.name),callback:function ($$v) {_vm.$set(_vm.localItem, "name", $$v)},expression:"localItem.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-switch',{attrs:{"label":_vm.localItem.enabled ? _vm.$vuetify.lang.t('$vuetify.general.enabled')
                          : _vm.$vuetify.lang.t('$vuetify.general.disabled')},model:{value:(_vm.localItem.enabled),callback:function ($$v) {_vm.$set(_vm.localItem, "enabled", $$v)},expression:"localItem.enabled"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('kurcc-autocomplete',{attrs:{"display-function":function (data) { return data.item.title; },"items":_vm.forms,"label":_vm.$vuetify.lang.t('$vuetify.pages.categories.form'),"loading":_vm.isLoadingForms,"multiple":false,"selected-items":_vm.selectedForm,"name":"Form"},on:{"update:selectedItems":function($event){_vm.selectedForm=$event},"update:selected-items":function($event){_vm.selectedForm=$event}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('kurcc-autocomplete',{attrs:{"items":_vm.groups,"label":_vm.$vuetify.lang.t('$vuetify.pages.categories.groups'),"loading":_vm.isLoadingGroups,"selected-items":_vm.selectedGroups,"name":"Group","rules":""},on:{"update:selectedItems":function($event){_vm.selectedGroups=$event},"update:selected-items":function($event){_vm.selectedGroups=$event}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Routine Inspection Duration","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.categories.routineInspectionDuration'),"min":0,"type":"number"},model:{value:(_vm.localItem.routine_inspection_duration_days),callback:function ($$v) {_vm.$set(_vm.localItem, "routine_inspection_duration_days", $$v)},expression:"localItem.routine_inspection_duration_days"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Deadline","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.categories.deadline'),"min":0,"type":"number"},model:{value:(_vm.localItem.deadline_days),callback:function ($$v) {_vm.$set(_vm.localItem, "deadline_days", $$v)},expression:"localItem.deadline_days"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{domProps:{"textContent":_vm._s(_vm.$vuetify.lang.t('$vuetify.pages.categories.grades'))}}),_c('v-card-text',[_vm._l((_vm.localItem.grades),function(grade,i){return _c('v-row',{key:i,attrs:{"align":"center","no-gutters":""}},[_c('v-col',[_c('kurcc-grade-item',{attrs:{"grade":grade},on:{"change":function($event){return _vm.updateGrade(i, $event)}}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeGrade(i)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)}),_c('v-btn',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s(_vm.$vuetify.lang.t('$vuetify.pages.categories.addGrade'))},on:{"click":function($event){return _vm.addGrade('X', 0)}}})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }