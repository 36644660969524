<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <validation-provider v-slot="{ errors }" name="Name" rules="required">
                    <v-text-field v-model="localItem.name" :error-messages="errors"
                                  :label="$vuetify.lang.t('$vuetify.general.name')"/>
                </validation-provider>
            </v-col>
            <v-col cols="12" sm="6">
                <v-row justify="end">
                    <v-switch v-model="localItem.enabled"
                              :label="localItem.enabled ? $vuetify.lang.t('$vuetify.general.enabled')
                              : $vuetify.lang.t('$vuetify.general.disabled')"/>
                </v-row>
            </v-col>
            <v-col cols="12">
                <kurcc-autocomplete
                    :display-function="data => data.item.title"
                    :items="forms"
                    :label="$vuetify.lang.t('$vuetify.pages.categories.form')"
                    :loading="isLoadingForms"
                    :multiple="false"
                    :selected-items.sync="selectedForm"
                    name="Form"/>
            </v-col>
            <v-col cols="12">
                <kurcc-autocomplete
                    :items="groups"
                    :label="$vuetify.lang.t('$vuetify.pages.categories.groups')"
                    :loading="isLoadingGroups"
                    :selected-items.sync="selectedGroups"
                    name="Group"
                    rules=""/>
            </v-col>
            <v-col cols="12" sm="6">
                <validation-provider v-slot="{ errors }" name="Routine Inspection Duration" rules="required|numeric">
                    <v-text-field v-model="localItem.routine_inspection_duration_days" :error-messages="errors"
                                  :label="$vuetify.lang.t('$vuetify.pages.categories.routineInspectionDuration')"
                                  :min="0" type="number"/>
                </validation-provider>
            </v-col>
            <v-col cols="12" sm="6">
                <validation-provider v-slot="{ errors }" name="Deadline" rules="required|numeric">
                    <v-text-field v-model="localItem.deadline_days" :error-messages="errors"
                                  :label="$vuetify.lang.t('$vuetify.pages.categories.deadline')"
                                  :min="0" type="number"/>
                </validation-provider>
            </v-col>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title v-text="$vuetify.lang.t('$vuetify.pages.categories.grades')"/>
                    <v-card-text>
                        <v-row v-for="(grade, i) in localItem.grades" :key="i" align="center" no-gutters>
                            <v-col>
                                <kurcc-grade-item :grade="grade" @change="updateGrade(i, $event)"/>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn icon @click="removeGrade(i)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-btn color="primary" @click="addGrade('X', 0)"
                               v-text="$vuetify.lang.t('$vuetify.pages.categories.addGrade')"/>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {removeItemFromArray} from "@/modules/app/helpers/utils";

export default {
    name: "KurccCategoriesDialog",
    props: {
        item: {
            required: true
        }
    },
    components: {
        KurccAutocomplete: () => import('@/modules/app/components/KurccAutocomplete'),
        KurccGradeItem: () => import('@/modules/categories/components/KurccGradeItem')
    },
    data() {
        return {
            groups: [],
            isLoadingGroups: false,
            selectedGroups: [],
            forms: [],
            isLoadingForms: false,
            selectedForm: undefined,
            removeItemFromArray
        }
    },
    computed: {
        localItem: {
            get() {
                return this.item;
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    watch: {
        selectedGroups: function (val) {
            this.localItem.group_ids = val.map(val => val.id)
        },
        selectedForm: function (val) {
            if (val)
                this.localItem.form_id = val.id
        },
    },
    methods: {
        getAllGroups() {
            this.isLoadingGroups = true
            this.$store.dispatch('getAllGroups', {withIncludes: false}).then((res) => {
                    this.groups = res.items
                }
            ).finally(() => (this.isLoadingGroups = false))
        },
        getAllForms() {
            this.isLoadingForms = true
            this.$store.dispatch('getAllForms', {
                withIncludes: false,
                additionalParams: ['enabled=1']
            }).then((res) => {
                    this.forms = res.items
                }
            ).finally(() => (this.isLoadingForms = false))
        },
        addGrade(name, value) {
            let item = this.$lodash.cloneDeep(this.localItem)
            if (item.grades) {
                item.grades.push({
                    name: name,
                    value: parseInt(value)
                })
            } else {
                item.grades = []
                item.grades.push({
                    name: name,
                    value: parseInt(value)
                })
            }
            this.localItem.grades = item.grades
        },
        removeGrade(index) {
            let item = this.$lodash.cloneDeep(this.localItem)
            item.grades?.splice(index, 1)
            this.localItem.grades = item.grades
        },
        updateGrade(index, value) {
            this.localItem.grades[index] = value
        }
    },
    created() {
        this.getAllGroups()
        this.getAllForms()
        this.selectedGroups = [...this.item.groups]
        this.selectedForm = this.item.form ? this.$lodash.cloneDeep(this.item.form) : undefined
    }
}
</script>
